import React from 'react'
import { graphql, PageProps } from 'gatsby'
import { LandingPageV2 } from '@talentinc/gatsby-theme-ecom/types/landingPageV2'
import {
  PageContextType,
  PageDataContextType,
} from '@talentinc/gatsby-theme-ecom/types/page'
import HeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/HeaderV2'
import NavTestHeaderV2 from '@talentinc/gatsby-theme-ecom/components/HeaderV2/NavTestHeaderV2'
import Footer from '@talentinc/gatsby-theme-ecom/components/Footer/Footer'
import Layout from '@talentinc/gatsby-theme-ecom/components/Layout'
import BaseLandingPageV2Template from '@talentinc/gatsby-theme-ecom/components/Templates/LandingPageV2'
import SEO from '@talentinc/gatsby-theme-ecom/components/SEO'
import '../styles/common.css'
import '../styles/success-stories.css'
import '../../../common-styles/match.css'
import '../../../common-styles/pricing-test.css'
import '../../../common-styles/decision-grid.css'
import '../../../common-styles/job-search-swap.css'
import { useLogFrontEndVisit } from '@talentinc/gatsby-theme-ecom/hooks/useLogFrontEndVisit'
import Hreflang from '@talentinc/gatsby-theme-ecom/components/Hreflang/Hreflang'

const LandingPageV2Template: React.FC<
  PageProps<{ landingPageV2: LandingPageV2 } & PageDataContextType>
> = (props) => {
  const { landingPageV2, ...pageData } = props.data
  const { hreflangs = [] } = props.pageContext as PageContextType
  useLogFrontEndVisit()

  return (
    <Layout pageTitle={landingPageV2.title} pageData={pageData}>
      <Hreflang data={hreflangs} />
      <SEO landingPage={landingPageV2} />
      <HeaderV2 />
      <NavTestHeaderV2 />
      <BaseLandingPageV2Template landingPage={landingPageV2} />
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  query LandingPagesV2BySlugAndBrand($slug: String!, $brandName: String!) {
    landingPageV2: contentfulLandingPageV2(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
      brand: { elemMatch: { name: { eq: $brandName } } }
    ) {
      ...LandingPageV2
    }
    brand: contentfulBrand(name: { eq: $brandName }) {
      ...Brand
    }
    headerV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "3Nd6Keg87eW5pGryLZS2ZA" }
    ) {
      ...HeaderV2
    }
    navTestHeaderV2: contentfulHeaderV2(
      brand: { elemMatch: { name: { eq: $brandName } } }
      contentful_id: { eq: "6yZRtzPjZxKCylBbpXF0oH" }
    ) {
      ...HeaderV2
    }
    footer: contentfulFooter(brand: { name: { eq: $brandName } }) {
      ...Footer
    }
    locales: allLocale(filter: { language: { eq: "en" } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default LandingPageV2Template
